export type Entity = {
  id: string;
  [key: string]: any;
};

function isObject<T>(item: T): boolean {
  return item && typeof item === "object" && !Array.isArray(item);
}

function isDate(obj: any): boolean {
  return obj instanceof Date;
}

export function patchObject<T extends Record<string, any>>(
  target: T,
  source: T,
  comparator?: T
): void {
  Object.keys(source).forEach((key) => {
    // Adding Date comparison
    if (isDate(source[key]) && isDate(target[key])) {
      if (!comparator || (comparator && +comparator[key] === +target[key])) {
        target[key as keyof T] = source[key];
      }
      return;
    }
    if (isObject(source[key]) && isObject(target[key])) {
      patchObject(target[key], source[key], comparator?.[key]);
      return;
    }

    if (comparator && comparator[key] !== target[key]) return;
    target[key as keyof T] = source[key];
  });
}

export function compareObjects<T extends Record<string, any>>(
  objectA: T,
  objectB: T
): Record<string, any> {
  const diff: Record<string, any> = {};

  Object.keys(objectB).forEach((key) => {
    if (objectA[key] !== objectB[key]) {
      if (
        typeof objectB[key] === "object" &&
        objectB[key] !== null &&
        typeof objectA[key] === "object" &&
        objectA[key] !== null
      ) {
        const nestedDiff = compareObjects(objectA[key], objectB[key]);
        if (Object.keys(nestedDiff).length > 0) {
          diff[key] = nestedDiff;
        }
      } else {
        diff[key] = objectB[key];
      }
    }
  });

  return diff;
}
