import type { Entity } from "./objectUtils";

export function patchArray<T extends Entity>(
  currentElements: T[],
  newElements: T[]
): T[] {
  // Create a map for quick access to the current elements by their id
  const elementMap = new Map(
    currentElements.map((element) => [element.id, element])
  );

  // Iterate over the new elements to update or add them
  newElements.forEach((element) => {
    elementMap.set(element.id, element);
  });

  // Return the updated array
  return Array.from(elementMap.values());
}
