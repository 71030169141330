import { absoluteValueToPercentage } from "./calculationUtils";
import type { ModelCalculationArguments } from "./modelArgumentUtils";

export type ModelCalculationResult = {
  profitInPercent: number;
  disabled: boolean;
  additionalInformation?: {
    [key: string]: { value: string | number | boolean; text: string };
  };
};

export function calculateWohnrecht(
  calculationArguments: ModelCalculationArguments
): ModelCalculationResult {
  const expectedTime = calculationArguments.occupationPeriod;
  // Baseline remaining values for very old people
  let profitInPercent = 55;
  if (expectedTime > 17) {
    profitInPercent = 0;
  } else if (expectedTime > 10) {
    profitInPercent = (-12 / 7) * expectedTime + 470 / 7;
  } else if (expectedTime > 6.5) {
    profitInPercent = (-5 / 3.5) * expectedTime + 450 / 7;
  }

  return {
    profitInPercent,
    disabled: calculationArguments.isInheritanceDesired || expectedTime > 17,
  };
}

export function calculateNiessbrauch(
  calculationArguments: ModelCalculationArguments
): ModelCalculationResult {
  const expectedTime = calculationArguments.occupationPeriod;
  const sellingPrice =
    calculationArguments.marketValue *
    (1 + calculationArguments.modelParameters.niessbrauchValueIncrease / 100) **
      expectedTime;
  const acquisitionCost =
    sellingPrice /
    (1 + calculationArguments.modelParameters.niessbrauchRoi / 100) **
      expectedTime;
  const oneTimePayment =
    acquisitionCost -
    calculationArguments.marketValue *
      (calculationArguments.additionalCostInPercent / 100);

  const profitInPercent = absoluteValueToPercentage(
    oneTimePayment,
    calculationArguments.marketValue
  );
  return {
    profitInPercent,
    disabled: calculationArguments.isInheritanceDesired || expectedTime > 63,
    additionalInformation: {
      sellingPrice: { value: sellingPrice, text: "Verkaufspreis" },
      acquisitionCost: { value: acquisitionCost, text: "Anschaffungskosten" },
    },
  };
}

export const calculateRueckmiete = (
  calculationArguments: ModelCalculationArguments
): ModelCalculationResult => {
  const expectedTime = calculationArguments.occupationPeriod;

  const initallyReducedPrice =
    calculationArguments.marketValue *
    (1 - calculationArguments.modelParameters.rueckmieteDepreciation / 100);

  const expectedRentPerAnnum =
    (initallyReducedPrice *
      calculationArguments.modelParameters.rueckmieteRoi) /
    100;

  const resultPrice =
    initallyReducedPrice - expectedTime * expectedRentPerAnnum;

  const profitInPercent = absoluteValueToPercentage(
    resultPrice,
    calculationArguments.marketValue
  );

  return {
    profitInPercent,
    disabled: calculationArguments.isInheritanceDesired,
    additionalInformation: {
      initallyReducedPrice: {
        value: initallyReducedPrice,
        text: "Preis mit Abschlägen",
      },
      expectedRentPerAnnum: {
        value: expectedRentPerAnnum,
        text: "Erwartete Miete pro Jahr",
      },
    },
  };
};

export const calculateUmkehrhypothek = (
  calculationArguments: ModelCalculationArguments
): ModelCalculationResult => {
  const minAge = calculationArguments.minPersonAge;
  const remainingShare = Math.max((minAge - 70) * 0.005 + 0.3, 0);

  const initiallyReducedPrice =
    calculationArguments.marketValue -
    calculationArguments.modelParameters.umkehrOnetimePayment;
  const priceOfRemainingShare = initiallyReducedPrice * remainingShare;

  const profitInPercent = absoluteValueToPercentage(
    Math.max(priceOfRemainingShare, 0),
    calculationArguments.marketValue
  );
  return {
    profitInPercent,
    disabled: minAge < 70,
    additionalInformation: {
      remainingShare: { value: remainingShare, text: "Verbleibender Anteil" },
      initiallyReducedPrice: {
        value: initiallyReducedPrice,
        text: "Preis mit Abschlägen",
      },
      priceOfRemainingShare: {
        value: priceOfRemainingShare,
        text: "Preis des verbleibenden Anteils",
      },
    },
  };
};

export const calculateSeniorenkredit = (
  calculationArguments: ModelCalculationArguments
): ModelCalculationResult => {
  const minAge = calculationArguments.minPersonAge;
  const expectedTime = calculationArguments.occupationPeriod;
  const remainingShares = Math.min(minAge, 90) * 0.5 - 5;
  const remainingSharePrice =
    calculationArguments.marketValue * (remainingShares / 100);
  const interestCharges =
    expectedTime *
    remainingSharePrice *
    (calculationArguments.modelParameters.seniorenkreditInterestRate / 100);
  const profitAfterCharges = remainingSharePrice - interestCharges;
  const profitInPercent = absoluteValueToPercentage(
    Math.max(profitAfterCharges, 0),
    calculationArguments.marketValue
  );

  return {
    profitInPercent,
    disabled: minAge < 60,
    additionalInformation: {
      remainingShares: { value: remainingShares, text: "Verbleibende Anteile" },
      remainingSharePrice: {
        value: remainingSharePrice,
        text: "Preis der verbleibenden Anteile",
      },
      interestCharges: { value: interestCharges, text: "Zinsen" },
      profitAfterCharges: {
        value: profitAfterCharges,
        text: "Gewinn nach Zinsen",
      },
    },
  };
};

export const calculateTeilverkauf = (
  calculationArguments: ModelCalculationArguments
): ModelCalculationResult => {
  const expectedTime = calculationArguments.occupationPeriod;

  const initialReduction = calculationArguments.marketValue / 2;
  const annualRent =
    initialReduction *
    (calculationArguments.modelParameters.teilverkaufInterestRate / 100);
  const absoluteProfit = initialReduction - annualRent * expectedTime;
  const profitInPercent = absoluteValueToPercentage(
    absoluteProfit,
    calculationArguments.marketValue
  );
  return {
    profitInPercent,
    disabled: false,
    additionalInformation: {
      initialReduction: {
        value: initialReduction,
        text: "Preis mit Abschlägen",
      },
      annualRent: { value: annualRent, text: "Jährliche Miete" },
    },
  };
};
