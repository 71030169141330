<template>
  <div
    style="height: 100vh; display: flex; flex-wrap: wrap; gap: 2rem"
    class="pa-8"
  >
    <v-dialog width="500" v-for="model in displayModels" :key="model.id">
      <template v-slot:activator="{ props }">
        <v-card
          v-bind="props"
          style="flex: 1"
          rounded="xl"
          elevation="4"
          :style="{
            backgroundColor: model.mainColor,
            'min-width': '26%',
            padding: '12px !important',
          }"
          class="model-card"
        >
          <section class="tw-h-full tw-flex tw-flex-col tw-items-stretch">
            <div
              style="
                display: flex;
                justify-content: space-between;
                color: white;
              "
            >
              <h2 class="tw-ml-3">
                {{ model.letter }}
              </h2>
              <h2 class="ml-2 text-center">
                {{ model.title }}
              </h2>
              <div @click.stop>
                <v-checkbox
                  :model-value="checkedModels.has(model.id)"
                  :style="{ width: '40px !important' }"
                  @change="onCheckboxChange(model.id)"
                  density="compact"
                />
              </div>
            </div>
            <h1
              class="text-center mt-2 tw-flex-grow tw-flex tw-items-center tw-justify-center"
              style="color: white"
            >
              {{
                model.sellingprice.toLocaleString("de-DE", {
                  style: "currency",
                  currency: "EUR",
                })
              }}
            </h1>
            <h4
              class="tw-text-white tw-flex-grow tw-flex tw-items-center tw-justify-center tw-text-center"
            >
              {{ model.subTitle }}
            </h4>
            <div class="progress-bar mx-4 mb-2">
              <div
                class="progress-fill"
                :style="{
                  width: model.profitInPercent + '%',
                  'background-color': model.mainColor,
                  opacity: '50%',
                }"
              >
                <h5 v-if="model.profitInPercent > 10" class="tw-text-white">
                  {{ Math.round(model.profitInPercent) }}%
                </h5>
              </div>
            </div>
          </section>
        </v-card>
      </template>

      <template v-slot:default="{ isActive }">
        <v-card
          :title="model.title"
          :style="{ 'background-color': model.mainColor }"
          style="color: white; justify-content: center; align-items: center"
        >
          <v-card-text :is="model" class="tw-text-center">
            <div>
              {{ model.subTitle }}
            </div>
            <div v-if="model.additionalInformation">
              <div
                v-for="info in Object.values(model.additionalInformation)"
                :key="info.text"
              >
                <span> {{ info.text }}: </span>
                <span class="tw-ml-2">
                  {{ info.value }}
                </span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text="Schliessen" @click="isActive.value = false" />
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-btn
      v-if="checkedModels.size >= 1"
      class="compare-button"
      text="Modelle vergleichen"
      rounded="l"
      size="x-large"
      color="hoffmannOrange"
      @click="isComparisonVisible = true"
    />
    <ComparisonUI
      v-if="isComparisonVisible"
      :compare-models="compareModels"
      @close="isComparisonVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import { computed, defineProps, ref } from "vue";
import type { ModelCalculationArguments } from "./modelArgumentUtils";
import type { DisplayableModel, ModelKey } from "./modelDisplay";
import ComparisonUI from "./ComparisonUI.vue";

const props = defineProps({
  displayModels: {
    type: Array as PropType<DisplayableModel[]>,
    required: true,
  },
  calculationArguments: {
    type: Object as PropType<ModelCalculationArguments>,
    required: true,
  },
});

const checkedModels = ref<Set<ModelKey>>(new Set());
const isComparisonVisible = ref(false);

const onCheckboxChange = (modelKey: ModelKey) => {
  const modelIndex = checkedModels.value.has(modelKey);
  if (modelIndex) {
    checkedModels.value.delete(modelKey);
  } else {
    checkedModels.value.add(modelKey);
  }
};

const compareModels = computed(() => {
  return props.displayModels.filter((model) =>
    checkedModels.value.has(model.id)
  );
});
</script>

<style scoped>
.model-card {
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
}

.model-card:hover {
  transform: scale(1.05);
}

.progress-bar {
  height: 28px;
  background-color: #f0f0f0;
  margin-top: 8px;
  overflow: hidden;
  border-radius: 8px;
}

.progress-fill {
  height: 100%;
  text-align: center;
  @apply tw-text-xl;
}

.compare-button {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
}

:deep(.v-input .v-input__details) {
  display: none;
}
</style>
