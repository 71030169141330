<template>
  <div class="align-center justify-center">
    <v-container fluid>
      <v-img
        class="mx-auto my-6"
        min-width="300"
        max-width="328"
        src="HOF_Logo_RGB.png"
      />

      <v-card
        class="mx-auto pa-12 pb-8"
        elevation="8"
        max-width="448"
        rounded="lg"
      >
        <div class="text-subtitle-1 text-medium-emphasis"></div>
        <v-form v-model="form" @submit.prevent="onSubmit">
          <v-text-field
            v-model="email"
            :readonly="loading"
            :rules="[required]"
            density="compact"
            label="Email Adresse"
            prepend-inner-icon="mdi-email-outline"
            variant="outlined"
          />
          <v-text-field
            v-model="password"
            :readonly="loading"
            :rules="[required]"
            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'"
            density="compact"
            label="Geben Sie Ihr Passwort ein"
            prepend-inner-icon="mdi-lock-outline"
            variant="outlined"
            @click:append-inner="visible = !visible"
            class="mt-4"
          />
          <div class="error-message" v-if="errorMessage">
            {{ errorMessage }}
          </div>
          <v-btn
            :disabled="!form"
            :loading="loading"
            block
            class="mt-8"
            color="hoffmannOrange"
            size="large"
            variant="elevated"
            type="submit"
          >
            Anmelden
          </v-btn>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import type { Ref } from "vue";
import { useAuthenticationService } from "@/router/authenticationService";
const form = ref(false);
const email = ref("");
const password = ref("");
const loading = ref(false);
const visible: Ref<boolean> = ref(false);

const errorMessage = ref("");

const onSubmit = () => {
  if (!form.value) return;

  loading.value = true;
  useAuthenticationService()
    .signIn(email.value, password.value)
    .then((errorMsg: void | string) => {
      if (errorMsg) {
        errorMessage.value = "Fehler bei der Anmeldung";
      }
      loading.value = false;
    });
};

const required = (v: string) => !!v || "Eingabe erforderlich";
</script>

<style scoped>
.error-message {
  width: 100%;
  color: white;
  background-color: red;
  border-radius: 8px;
  padding: 8px;
  text-align: center;
}
</style>
