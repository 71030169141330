export enum PROPERTY_TYPE {
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  TRADE_SITE = "TRADE_SITE",
  GARAGE = "GARAGE",
  SHORT_TERM_ACCOMMODATION = "SHORT_TERM_ACCOMMODATION",
  OFFICE = "OFFICE",
  GASTRONOMY = "GASTRONOMY",
  INDUSTRY = "INDUSTRY",
  STORE = "STORE",
  SPECIAL_PURPOSE = "SPECIAL_PURPOSE",
  INVESTMENT = "INVESTMENT",
}

export enum FLAT_TYPE {
  ROOF_STOREY = "ROOF_STOREY",
  LOFT = "LOFT",
  MAISONETTE = "MAISONETTE",
  PENTHOUSE = "PENTHOUSE",
  TERRACED_FLAT = "TERRACED_FLAT",
  GROUND_FLOOR = "GROUND_FLOOR",
  APARTMENT = "APARTMENT",
  RAISED_GROUND_FLOOR = "RAISED_GROUND_FLOOR",
  HALF_BASEMENT = "HALF_BASEMENT",
  OTHER = "OTHER",
}

export enum HOUSE_TYPE {
  SINGLE_FAMILY_HOUSE = "SINGLE_FAMILY_HOUSE",
  TWO_FAMILY_HOUSE = "TWO_FAMILY_HOUSE",
  MID_TERRACE_HOUSE = "MID_TERRACE_HOUSE",
  TERRACE_END_HOUSE = "TERRACE_END_HOUSE",
  MULTI_FAMILY_HOUSE = "MULTI_FAMILY_HOUSE",
  SEMIDETACHED_HOUSE = "SEMIDETACHED_HOUSE",
}

type PropertyStatus = {
  name: string;
  color: string;
};

export enum GENDER {
  FEMALE = "Frau",
  MALE = "Herr",
}

export enum MARITAL_STATUS {
  Single = "Ledig",
  DomesticPartnership = "Verpartnert",
  Married = "Verheiratet",
  Divorced = "Geschieden",
  Widowed = "Verwitwet",
}

export type PropstackCustomUnitFields = {
  market_value: { value: number | null };
  owner_name: { value: string | null };
  owner_birthday: { value: string | null };
  owner_sex: { value: number | null };
  partner_name: { value: string | null };
  partner_birthday: { value: string | null };
  partner_sex: { value: number | null };
  heritage: { value: boolean | null };
  longlife_residence: { value: boolean | null };
  desired_remaining_stay: { value: number | null };
  marital_status: { value: number | null };
  is_initialized: { value: boolean | null };
  niessbrauch_roi: { value: number | null };
  niessbrauch_value_increase: { value: number | null };
  rueckmiete_roi: { value: number | null };
  rueckmiete_depreciation: { value: number | null };
  seniorenkredit_interest_rate: { value: number | null };
  teilverkauf_interest_rate: { value: number | null };
  umkehr_interest_rate: { value: number | null };
  umkehr_onetime_payment: { value: number | null };
};

export type PropStackUnitType = {
  id: number;
  name: string | null;
  street: string | null;
  house_number: string | null;
  zip_code: number | null;
  city: string | null;
  address: string | null;
  short_address: string | null;
  broker?: {
    first_name: string;
    last_name: string;
    avatar: string | null;
  } | null;
  living_space: { value: number | null };
  plot_area: { value: number | null };
  property_space_value: number | null;
  property_status: PropertyStatus;
  rs_type: PROPERTY_TYPE | null;
  rs_category: FLAT_TYPE | HOUSE_TYPE | null;
  created_at: string;
  updated_at: string | null;
  images: [{ small_thumb_url: string; thumb_url: string }];
  custom_fields: PropstackCustomUnitFields;
  energy_efficiency_class: { label: "string"; value: string | null };
  floor: number | null;
  number_of_floors: number | null;
  number_of_rooms: { value: number | null };
  number_of_bath_rooms: { value: number | null };
  price: { value: number | null };
};

export type LocalCustomUnitFields = Omit<
  PropstackCustomUnitFields,
  "owner_sex" | "partner_sex" | "marital_status"
> & {
  owner_sex: { value: GENDER | null };
  partner_sex: { value: GENDER | null };
  marital_status: { value: MARITAL_STATUS | null };
};

export type LocalUnit = {
  id: string;
  name: string | null;
  street: string | null;
  house_number: string | null;
  zip_code: number | null;
  city: string | null;
  address: string | null;
  short_address: string | null;
  broker?: {
    first_name: string | null;
    last_name: string | null;
    avatar: string | null;
  } | null;
  living_space: { value: number | null };
  plot_area: { value: number | null };
  property_space_value: number | null;
  property_status: PropertyStatus;
  rs_type: PROPERTY_TYPE | null;
  rs_category: FLAT_TYPE | HOUSE_TYPE | null;
  created_at: string;
  updated_at: string | null;
  images: [{ small_thumb_url: string; thumb_url: string }];
  custom_fields: LocalCustomUnitFields;
  energy_efficiency_class: { label: "string"; value: string | null };
  floor: number | null;
  number_of_floors: number | null;
  number_of_rooms: { value: number | null };
  number_of_bath_rooms: { value: number | null };
  price: { value: number | null };
};
