<template>
  <div class="ma-8 px-2 tw-space-y-4">
    <section class="tw-flex tw-space-x-8">
      <v-text-field
        v-model.trim="globalSettings.berlinAdditionalCost"
        color="hoffmannRot"
        label="Kaufnebenkosten Berlin"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-percent"
        @change="hasChanges = true"
      />
      <v-text-field
        v-model.trim="globalSettings.elseAdditionalCost"
        color="hoffmannRot"
        label="Kaufnebenkosten anderswo"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-percent"
        @change="hasChanges = true"
      />
    </section>
    <section class="tw-flex tw-space-x-8">
      <v-text-field
        v-model.trim="globalSettings.nießbrauchROI"
        color="hoffmannRot"
        label="Nießrauch Rendite (in %)"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-percent"
        @change="hasChanges = true"
      />
      <v-text-field
        v-model.trim="globalSettings.nießbrauchValueIncrease"
        color="hoffmannRot"
        label="Nießbrauch Wertsteigerung (in %)"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-percent"
        @change="hasChanges = true"
      />
    </section>
    <section class="tw-flex tw-space-x-8">
      <v-text-field
        v-model.trim="globalSettings.rueckmieteROI"
        color="hoffmannRot"
        label="Rückmiete Rendite (in %)"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-percent"
        @change="hasChanges = true"
      />
      <v-text-field
        v-model.trim="globalSettings.rueckmieteDepreciation"
        color="hoffmannRot"
        label="Rückmiete Abschlag (in %)"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-percent"
        @change="hasChanges = true"
      />
    </section>
    <section class="tw-flex tw-space-x-8">
      <v-text-field
        v-model.trim="globalSettings.umkehrInterestRate"
        color="hoffmannRot"
        label="Umkehrhypothek Zinsen (in €)"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-percent"
        @change="hasChanges = true"
      />
      <v-text-field
        v-model.trim="globalSettings.umkehrOnetimePayment"
        color="hoffmannRot"
        label="Umkehrhypothek Einmalzahlung"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-currency-eur"
        @change="hasChanges = true"
      />
    </section>

    <v-text-field
      v-model.trim="globalSettings.seniorenkreditInterestRate"
      color="hoffmannRot"
      label="Seniorenkredit Zinsen (in %)"
      variant="underlined"
      type="number"
      prepend-inner-icon="mdi-percent"
      class="tw-max-w-[49%]"
      @change="hasChanges = true"
    />

    <v-text-field
      v-model.trim="globalSettings.teilverkaufInterestRate"
      color="hoffmannRot"
      label="Teilverkauf Zinsen (in %)"
      variant="underlined"
      type="number"
      prepend-inner-icon="mdi-percent"
      class="tw-max-w-[49%]"
      @change="hasChanges = true"
    />
    <section class="tw-flex tw-justify-end tw-items-center">
      <v-btn
        text="Änderungen übernehmen"
        class="tw-ml-2"
        color="hoffmannRot"
        size="large"
        :disabled="!hasChanges"
        @click="onUpdate"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { getGlobalSettingsService } from "./settingService";

const globalSettingsService = getGlobalSettingsService();
const globalSettings = globalSettingsService.getSyncedGlobalSettings();

const hasChanges = ref(false);

const onUpdate = () => {
  globalSettingsService.updateSettings(globalSettings.value);
  hasChanges.value = false;
};
</script>
