<template>
  <v-dialog :model-value="true">
    <v-card
      style="background-color: rgb(var(--v-theme-background))"
      class="comparison-card !tw-rounded-xl"
    >
      <table class="tw-h-full tw-w-full">
        <tr class="header">
          <th style="width: 15%">
            <div style="display: flex; justify-content: space-around">
              <v-tooltip text="Zurück zu den Ergebnissen">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    color="hoffmannOrange"
                    icon="mdi-arrow-left"
                    variant="outlined"
                    @click="emit('close')"
                  >
                  </v-btn>
                </template>
              </v-tooltip>
              <v-tooltip text="Export">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    color="hoffmannOrange"
                    icon="mdi-tray-arrow-down"
                  >
                  </v-btn>
                </template>
              </v-tooltip>
            </div>
          </th>
          <th
            v-for="model in compareModels"
            :key="`title-${model.id}`"
            :style="{
              'background-color': model.mainColor,
              color: 'white',
              'border-radius': '12px 12px 0px 0px',
              width: `${comparisonModelListWidth}%`,
            }"
          >
            <h2>{{ `${model.letter}) ${model.title}` }}</h2>
          </th>
        </tr>
        <tr>
          <td>
            <h3
              style="color: rgb(var(--v-theme-hoffmannRot))"
              class="tw-text-center"
            >
              Anteilige Auszahlung
            </h3>
          </td>
          <td
            v-for="model in compareModels"
            :key="`progress-${model.id}`"
            :style="{ 'background-color': model.lightColor }"
          >
            <div class="progress-bar mx-4 mb-2">
              <div
                class="progress-fill"
                :style="{
                  width: model.profitInPercent + '%',
                  'background-color': model.mainColor,
                }"
              >
                <h3 class="text-center" style="color: white">
                  {{ Math.round(model.profitInPercent) }}%
                </h3>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h3
              style="color: rgb(var(--v-theme-hoffmannRot))"
              class="tw-text-center"
            >
              Endbetrag
            </h3>
          </td>
          <td
            v-for="model in compareModels"
            :key="`final-value-${model.id}`"
            :style="{ 'background-color': model.lightColor }"
          >
            <span>
              <h3 style="text-decoration: underline">
                {{
                  model.sellingprice.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })
                }}
              </h3>
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <h3
              style="color: rgb(var(--v-theme-hoffmannRot))"
              class="tw-text-center"
            >
              Vorteile
            </h3>
          </td>
          <td
            v-for="model in compareModels"
            :key="`runtime-${model.id}`"
            :style="{ 'background-color': model.lightColor }"
          >
            <span
              v-for="(advantage, index) in model.advantages"
              :key="`runtime-${model.id}-${index}`"
            >
              <h4>
                <v-icon icon="mdi-check-bold" color="green" />
                {{ advantage }}
              </h4>
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <h3
              style="color: rgb(var(--v-theme-hoffmannRot))"
              class="tw-text-center"
            >
              Zu berücksichtigen
            </h3>
          </td>
          <td
            v-for="model in compareModels"
            :key="`runtime-${model.id}`"
            :style="{
              'background-color': model.lightColor,
            }"
            style="border-radius: 0px 0px 12px 12px"
          >
            <span
              v-for="(disadvantage, index) in model.disadvantages"
              :key="`runtime-${model.id}-${index}`"
            >
              <h4>
                <v-icon icon="mdi-checkbox-blank-circle" color="grey" />
                {{ disadvantage }}
              </h4>
            </span>
          </td>
        </tr>
      </table>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, toRef, type PropType } from "vue";
import type { DisplayableModel } from "./modelDisplay";

const props = defineProps({
  compareModels: {
    type: Array as PropType<DisplayableModel[]>,
    required: true,
  },
});

const emit = defineEmits(["close"]);

const compareModels = toRef(props, "compareModels");

const comparisonModelListWidth = computed(
  () => (100 - 15) / compareModels.value.length
);
</script>

<style scoped>
:deep(.comparison-card .progress-bar) {
  height: 2rem;
  background-color: #f0f0f0;
  margin-top: 8px;
  overflow: hidden;
  border-radius: 8px;
}

:deep(.comparison-card .progress-fill) {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.comparison-card table) {
  border-spacing: 16px -1px;
  border-collapse: separate;
}

:deep(.comparison-card table .header) {
  height: 15%;
  position: sticky;
  top: 0;
  z-index: 1;
}

:deep(.comparison-card table .header th) {
  padding: 8px;
}

:deep(.comparison-card table tr td span) {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px 8px;
}

table tr td:first-child {
  border-top: 1px solid gray;
}
</style>
