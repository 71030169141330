<template>
  <div class="tw-p-6 tw-max-h-[100vh] tw-flex tw-flex-col">
    <v-text-field
      v-model.trim="searchForUnit"
      append-icon="mdi-magnify"
      label="Suchen"
      variant="underlined"
      hide-details
      style="flex: 3"
      density="compact"
      color="hoffmannRot"
      class="ml-2"
    ></v-text-field>
    <v-list style="background-color: #f7e4dd" class="scrollable-unit-list">
      <v-list-item
        v-for="(unit, index) in filteredUnitList"
        :key="unit.id ?? index"
        :value="unit.id"
        class="mt-2 mx-1"
        elevation="2"
        rounded="s"
        @click="onUnitClick(unit.id)"
        style="background-color: white"
      >
        <div style="display: flex; align-items: center">
          <v-img
            v-if="unit.images.length"
            class="tw-rounded-full !tw-max-w-[80px]"
            cover
            :src="unit.images[0].small_thumb_url"
          />
          <v-avatar v-else size="80" image="/default_unit_image.png" />
          <v-avatar
            :image="unit.broker?.avatar ?? undefined"
            :size="35"
            class="tw--left-4 tw-top-4"
          />
          <strong
            class="font-weight-bold text-h6 tw-ml-3 tw-flex-1"
            style="color: rgb(var(--v-theme-hoffmannRot))"
          >
            {{ `${unit.address}` }}
          </strong>
          <div
            class="tw-px-3 tw-mr-3 tw-py-1 tw-text-white tw-rounded-md"
            :style="{ 'background-color': unit.property_status.color }"
          >
            {{ unit.property_status.name }}
          </div>
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, toRefs } from "vue";
import type { Ref } from "vue";
import router from "@/router";
import { useUnitStore } from "@/stateManagement/unitStore";
import { ROUTE_NAME } from "@/router/routeNames";

const unitStore = useUnitStore();
const { allUnitsSorted } = toRefs(unitStore);

const onUnitClick = (unitId: string | null) => {
  if (!unitId) {
    return;
  }
  router.push({ name: ROUTE_NAME.UNIT_INPUT_VIEW, params: { slug: unitId } });
};

const searchForUnit: Ref<string> = ref("");

const filteredUnitList = computed(() => {
  const searchValue = searchForUnit.value
    ? searchForUnit.value.toLowerCase()
    : "";

  if (!allUnitsSorted.value) {
    return [];
  }

  if (!searchValue) {
    return allUnitsSorted.value;
  }

  return allUnitsSorted.value.filter((unit) => {
    const propertiesToSearch = [
      unit.address,
      unit.name,
      unit.broker?.first_name,
      unit.broker?.last_name,
    ];
    const searchTerms = searchValue.split(" ");

    return searchTerms.every((term) => {
      return propertiesToSearch.some((text) =>
        text?.toString().toLowerCase().includes(term)
      );
    });
  });
});
</script>
