import { computed } from "vue";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import router from "@/router";
import { authenticationProvider } from "@/firebase/firebaseFunctions";
import * as Sentry from "@sentry/vue";

export const useAuthenticationService = () => {
  const getUser = async () => {
    await new Promise((resolve, reject) => {
      const removeListener = onAuthStateChanged(
        authenticationProvider,
        (user) => {
          removeListener();
          resolve(user);
        },
        reject
      );
    });
    return authenticationProvider.currentUser;
  };

  const logout = () => {
    console.log("logout");
    signOut(authenticationProvider);
    router.push("/login");
  };

  const signIn = async (email: string, password: string) => {
    return signInWithEmailAndPassword(authenticationProvider, email, password)
      .then(() => {
        console.log("Successfully signed in!");
        router.push("/");
      })
      .catch((error) => {
        console.error(error.code);
        Sentry.captureException(error);
        return error.code as string;
      });
  };

  const isLoggedInComputed = computed(() => getUser() !== null);

  return {
    authenticationProvider,
    signIn,
    logout,
    getUser,
    isLoggedInComputed,
  };
};
