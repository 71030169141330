import { createRouter, createWebHistory } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import UnitListView from "@/views/UnitListView.vue";
import SiteView from "@/views/AppView.vue";
import ResultView from "@/features/unitResults/ResultView.vue";
import UnitInputView from "@/views/UnitInputView.vue";
import UnitView from "@/views/UnitView.vue";
import GlobalSettingsView from "@/features/globalSettings/GlobalSettingsView.vue";
import { useAuthenticationService } from "./authenticationService";
import { ROUTE_NAME } from "./routeNames";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/login",
      name: "login",
      component: LoginView,
    },
    {
      path: "/app",
      name: "app",
      component: SiteView,
      children: [
        {
          path: "objekte",
          name: ROUTE_NAME.ALL_UNITS_VIEW,
          component: UnitListView,
        },
        {
          path: "objekt/:slug",
          name: ROUTE_NAME.UNIT_VIEW,
          component: UnitView,
          children: [
            {
              path: ROUTE_NAME.UNIT_INPUT_VIEW,
              name: ROUTE_NAME.UNIT_INPUT_VIEW,
              component: UnitInputView,
            },
            {
              path: ROUTE_NAME.UNIT_RESULT_VIEW,
              name: ROUTE_NAME.UNIT_RESULT_VIEW,
              component: ResultView,
            },
          ],
        },
        {
          path: ROUTE_NAME.GLOBAL_SETTINGS,
          name: ROUTE_NAME.GLOBAL_SETTINGS,
          component: GlobalSettingsView,
        },
      ],
      meta: { requiresAuth: true },
      redirect: { name: ROUTE_NAME.ALL_UNITS_VIEW },
    },
    {
      path: "/",
      redirect: { name: ROUTE_NAME.ALL_UNITS_VIEW },
      meta: { requiresAuth: true },
    },
  ],
});

router.beforeEach(async (to) => {
  const currentUser = await useAuthenticationService().getUser();
  if (to.meta.requiresAuth && currentUser) {
    return true;
  } else if (currentUser) {
    return { name: ROUTE_NAME.ALL_UNITS_VIEW };
  } else if (to.name !== "login") {
    return { name: "login" };
  }
});

export default router;
