type Dictionary = {
  [key: string]: any;
};

export function omitKeys<T extends Dictionary, V extends keyof T>(
  raw: T,
  keys: V[]
): Omit<T, V> {
  if (keys.length === 0) return raw;

  return Object.keys(raw)
    .filter((key) => !keys.includes(key as V))
    .reduce((obj, key: string) => {
      obj[key] = raw[key];
      return obj;
    }, {} as Dictionary) as T;
}

/**  Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds.
 */
export function debounce<T extends (...args: any[]) => Promise<any>>(
  func: T,
  wait: number,
  immediate: boolean = false
): (...args: Parameters<T>) => Promise<ReturnType<T> | undefined> {
  let timeout: number | null = null;

  return function (
    this: ThisParameterType<T>,
    ...args: Parameters<T>
  ): Promise<ReturnType<T> | undefined> {
    const context = this;

    return new Promise<ReturnType<T> | undefined>((resolve, reject) => {
      const later = () => {
        timeout = null;
        if (!immediate) {
          resolve(func.apply(context, args).catch(reject));
        }
      };

      const callNow = immediate && !timeout;
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      timeout = window.setTimeout(later, wait);

      if (callNow) {
        resolve(func.apply(context, args).catch(reject));
      }
    });
  };
}

export function patchMap<T extends Dictionary>(
  map: Map<string, { persistedState: T; changedValues: Partial<T> }>,
  newItems: T[]
): void {
  newItems.forEach((item) => {
    const mapItem = map.get(item.id);

    if (mapItem) {
      // Update persistedState only for keys not in changedValues
      Object.keys(item).forEach((key) => {
        if (key !== "id" && !mapItem.changedValues.hasOwnProperty(key)) {
          mapItem.persistedState[key as keyof T] = item[key];
        }
      });
    } else {
      // Add new entry to the map
      map.set(item.id, { persistedState: item, changedValues: {} });
    }
  });
}
