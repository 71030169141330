import "./assets/main.css";

import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import * as Sentry from "@sentry/vue";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { hoffmanLightTheme } from "@/assets/styling/customLightTheme";
import "@mdi/font/css/materialdesignicons.css";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const app = createApp(App);

const vuetify = createVuetify({
  theme: {
    defaultTheme: "hoffmanLightTheme",
    themes: {
      hoffmanLightTheme,
    },
  },
  components,
  directives,
  icons: {
    defaultSet: "mdi",
  },
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

Sentry.init({
  app,
  dsn: "https://415515a106cbce3dedaa66ccc5558785@o4506780542828544.ingest.sentry.io/4506780739436544",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(pinia);
app.use(router);
app.use(vuetify);
app.mount("#app");
