<template>
  <main>
    <v-dialog width="500" :model-value="!!errorMessage" persistent>
      <v-card title="Fehlende Daten für Berechnung">
        <v-card-text>
          {{ errorMessage }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="hoffmannRot"
            block
            variant="tonal"
            :to="{ name: ROUTE_NAME.UNIT_INPUT_VIEW }"
            >Zurück zur Eingabemaske</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ResultUI
      v-if="modelCalculationArguments && modelResults"
      :calculation-arguments="modelCalculationArguments"
      :display-models="modelResults"
    />
  </main>
</template>

<script setup lang="ts">
import { useUnitStore } from "@/stateManagement/unitStore";
import ResultUI from "./ResultUI.vue";
import { computed, ref, toRef } from "vue";
import { validateUnitData, getModelArguments } from "./modelArgumentUtils";
import { getAvailableModel } from "./modelDisplay";
import { ROUTE_NAME } from "@/router/routeNames";

const currentUnit = toRef(useUnitStore(), "currentUnit");

const errorMessage = ref<string | null>(null);

const validatedModelArguments = computed(() => {
  const modelArguments = validateUnitData(currentUnit.value);
  if (typeof modelArguments === "string") {
    errorMessage.value = modelArguments;
    return;
  }
  errorMessage.value = null;
  return modelArguments;
});

const modelCalculationArguments = computed(() => {
  if (!validatedModelArguments.value) return;
  return getModelArguments(validatedModelArguments.value);
});

const modelResults = computed(() => {
  if (!modelCalculationArguments.value) return;
  const availableModels = getAvailableModel(modelCalculationArguments.value);
  return availableModels.sort(
    (m1, m2) => m2.profitInPercent - m1.profitInPercent
  );
});
</script>
