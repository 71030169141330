import axios, { type AxiosInstance } from "axios";
import type { LocalUnit, PropStackUnitType } from "./propstackTypes";
import { convertToLocalFormat, convertToPropstackFormat } from "./utils";
import * as Sentry from "@sentry/vue";

export class PropstackAPIConnector {
  private client: AxiosInstance;
  private readonly apiKey: string | undefined;
  private currentPage = 1;
  private isFetchingMore = false;

  constructor() {
    // @ts-ignore
    this.apiKey = import.meta.env.VITE_APP_PROPSTACK_API_KEY;

    if (!this.apiKey) throw new Error("Unable to detect API key");

    this.client = axios.create({
      baseURL: "https://api.propstack.de/v1/",
      headers: {
        "X-API-KEY": this.apiKey,
      },
    });
  }

  async getObjects(limit = 35): Promise<LocalUnit[]> {
    try {
      const response = await this.client.get("/units", {
        params: {
          page: this.currentPage,
          per: limit,
          sort_by: "updated_at",
          order: "desc",
          expand: 1,
        },
      });
      return response.data.map((u: PropStackUnitType) =>
        convertToLocalFormat(u)
      );
    } catch (error) {
      console.error("Error fetching objects from Propstack API:", error);
      Sentry.captureException(error);
      throw error;
    }
  }

  async getContacts(ids: string[]): Promise<any> {
    try {
      const response = await this.client.get("/contacts", {
        params: {
          ids: ids.join(","),
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching contacts from Propstack API:", error);
      Sentry.captureException(error);
      throw error;
    }
  }

  async getMoreObjects(limit: number = 25): Promise<LocalUnit[]> {
    if (this.isFetchingMore) return Promise.reject();
    this.isFetchingMore = true;
    this.currentPage++;
    const objects = this.getObjects(limit);
    this.isFetchingMore = false;
    return objects;
  }

  async updateObject(
    id: string,
    updateData: Partial<LocalUnit>
  ): Promise<LocalUnit | null> {
    try {
      console.log("TRY PUT");
      const body = convertToPropstackFormat(updateData);
      const response = await this.client.put(
        `/units/${id}`,
        JSON.stringify({
          property: body,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return convertToLocalFormat(response.data);
    } catch (error) {
      console.error("Error updating object in Propstack API:", error);
      Sentry.captureException(error);
      return null;
    }
  }
}
