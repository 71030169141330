import { ref } from "vue";
import type { Ref } from "vue";
import { db } from "@/firebase/firebaseFunctions";
import {
  collection,
  onSnapshot,
  updateDoc,
  doc,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import type { GlobalSettings } from "./types";
import { omitKeys } from "@/stateManagement/utils";
import * as Sentry from "@sentry/vue";

const DEFAULT_BERLIN_ADDITIONAL_COST = 11.33;
const DEFAULT_ELSE_ADDITIONAL_COST = 11.83;
const DEFAULT_NIEßBRAUCH_RETURN = 5.0;
const DEFAULT_NIEßBRAUCH_VALUE_INCREASE = 2.0;
const DEFAULT_RUECKMIETE_RETURN = 5.0;
const DEFAULT_RUECKMIETE_DEPRECIATION = 30.0;
const DEFAULT_SENIORENKREDIT_INTEREST_RATE = 4.25;
const DEFAULT_TEILVERKAUF_INTEREST_RATE = 5.95;
const DEFAULT_UMKEHR_INTEREST_RATE = 6.95;
const DEFAULT_UMKEHR_ONETIME_PAYMENT = 250000;

export const getGlobalSettingsService = () => {
  const getSyncedGlobalSettings = () => {
    const settings: Ref<GlobalSettings & { id: string }> = ref({
      id: "",
      berlinAdditionalCost: DEFAULT_BERLIN_ADDITIONAL_COST,
      elseAdditionalCost: DEFAULT_ELSE_ADDITIONAL_COST,
      nießbrauchROI: DEFAULT_NIEßBRAUCH_RETURN,
      nießbrauchValueIncrease: DEFAULT_NIEßBRAUCH_VALUE_INCREASE,
      rueckmieteROI: DEFAULT_RUECKMIETE_RETURN,
      rueckmieteDepreciation: DEFAULT_RUECKMIETE_DEPRECIATION,
      seniorenkreditInterestRate: DEFAULT_SENIORENKREDIT_INTEREST_RATE,
      teilverkaufInterestRate: DEFAULT_TEILVERKAUF_INTEREST_RATE,
      umkehrInterestRate: DEFAULT_UMKEHR_INTEREST_RATE,
      umkehrOnetimePayment: DEFAULT_UMKEHR_ONETIME_PAYMENT,
    });
    onSnapshot(collection(db, "settings"), async (querySnapshot) => {
      if (!querySnapshot.size) {
        console.error("No entry for global settings in database");
        Sentry.captureEvent({
          message: "No entry for global settings in database",
        });
        return;
      }
      const globalSetting = querySnapshot
        .docs[0] as QueryDocumentSnapshot<GlobalSettings>;
      settings.value = {
        id: globalSetting.id,
        ...globalSetting.data(),
      };
    });
    return settings;
  };

  const updateSettings = async (
    updateSettings: GlobalSettings & { id: string }
  ) => {
    const docRef = doc(db, "settings", updateSettings.id);
    return updateDoc(docRef, omitKeys(updateSettings, ["id"]));
  };

  return {
    getSyncedGlobalSettings,
    updateSettings,
  };
};
