import { GENDER } from "@/propstack/propstackTypes";
import { differenceInYears } from "date-fns";
import { BERLIN_ZIP_CODES, LIFE_EXPECTANCY_TABLE } from "./constants";

export function calculateAge(birthday: Date) {
  return differenceInYears(new Date(), birthday);
}

export type PersonWithLifeExpectancy = {
  age: number;
  gender: GENDER;
  lifeExpectancy: number;
};

export const getRelevantPersonWithLifeExpectancy = (
  persons: { birthday: Date; gender: GENDER }[]
) => {
  const personsWithLifeExpectancies = persons.map<PersonWithLifeExpectancy>(
    (person) => {
      const age = calculateAge(person.birthday);
      if (age < 63) return { gender: person.gender, age, lifeExpectancy: 30 };
      if (age > 100) return { gender: person.gender, age, lifeExpectancy: 1 };
      const expectancy = LIFE_EXPECTANCY_TABLE.find((e) => e.age === age);
      if (!expectancy)
        return { gender: person.gender, age, lifeExpectancy: 30 };

      return {
        age,
        gender: person.gender,
        lifeExpectancy: expectancy[person.gender === GENDER.FEMALE ? "w" : "m"],
      };
    }
  );
  return personsWithLifeExpectancies.reduce((longestLivingPerson, person) => {
    if (person.lifeExpectancy > longestLivingPerson.lifeExpectancy)
      return person;
    return longestLivingPerson;
  });
};

export const getRemainingTimeForUserPreference = (
  maxLifeExpectancy: number,
  durationOfStay: string | number | null
) => {
  if (!durationOfStay) return maxLifeExpectancy;
  const remainingTime = Number(durationOfStay);
  return Math.min(maxLifeExpectancy, remainingTime);
};

export const absoluteValueToPercentage = (
  absValue: number,
  marketValue: number
) => {
  return (absValue / marketValue) * 100;
};

export const getAdditionalPurchasingCostBasedOnZipcode = (
  postalCode: string
) => {
  if (BERLIN_ZIP_CODES.find((zipCode) => postalCode === zipCode)) {
    return 11.33;
  }
  return 11.83;
};
