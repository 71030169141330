import type { ModelCalculationArguments } from "./modelArgumentUtils";
import {
  calculateNiessbrauch,
  calculateRueckmiete,
  calculateSeniorenkredit,
  calculateTeilverkauf,
  calculateUmkehrhypothek,
  calculateWohnrecht,
  type ModelCalculationResult,
} from "./modelCalculation";

export enum ModelKey {
  TEILVERKAUF = "teilverkauf",
  UMKEHR = "umkehr",
  RUECKMIETE = "rueckmiete",
  NIESSBRAUCH = "niessbrauch",
  SENIORENKREDIT = "seniorenkredit",
  WOHNRECHT = "wohnrecht",
  FAMILIE = "familie",
  IMMORENTE = "immorente",
  NEUES_ZUHAUSE = "neues_zuhause",
}

export type DisplayableModel = {
  id: ModelKey;
  letter: string;
  title: string;
  subTitle: string;
  mainColor: string;
  lightColor: string;
  advantages: string[];
  disadvantages: string[];
  sellingprice: number;
} & ModelCalculationResult;

export function getAvailableModel(
  calculationArguments: ModelCalculationArguments
): DisplayableModel[] {
  const models = [
    {
      id: ModelKey.WOHNRECHT,
      letter: "A",
      title: "Einmalzahlung & Wohnrecht",
      subTitle: "Ein diskreter und provisionsfreier Verkauf der Immobilie",
      mainColor: "#4C4464",
      lightColor: "#a6a2b2",
      advantages: [
        "kostenlos Wohnen bis zum Lebensende weiterhin möglich",
        "diskreter & provisionsfreier Verkauf",
        "hohe Auszahlbeträge",
        "auf Ihren Wunsch ist eine Ablösung des Wohnungsrechts gegen eine weitere faire Einmalzahlung möglich",
        "umfangreiche finanzielle Absicherung, der Käufer übernimmt die Kosten für Reparatur und Instandhaltungen",
        "bei einer Wohnungseigentümergemeinschaft übernimmt der Käufer zusätzlich die Kosten für Hausverwaltung, Zuführung in die Erhaltungs­rücklage und eventuelle Sonderumlagen",
        "als Käufer Ihrer Immobilie haben Sie in der Regel einen professionellen Partner für eine gute und langfristige Zusammenarbeit an Ihrer Seite",
        "erstrangige Absicherung Ihrer Rechte im Grundbuch",
        "lebenslanges Wohnungsrecht für Sie und ggf. Ihren Partner",
        "Vermietung einer Einliegerwohnung ist möglich",
      ],
      disadvantages: [
        "komplette Vermietung nicht möglich",
        "Mindestalter bei Frauen 70 Jahre, bei Männern 68 Jahren",
        "Vererbung der Immobilie ist nicht mehr möglich",
      ],
      ...calculateWohnrecht(calculationArguments),
    },
    {
      id: ModelKey.NIESSBRAUCH,
      letter: "B",
      title: "Einmalzahlung & Nießbrauchrecht",
      subTitle: "Alle Immobilienarten. Vermietung möglich. Schon ab 63 Jahren.",
      mainColor: "#EB6B4A",
      lightColor: "#f3a692",
      advantages: [
        "bereits ab einem Alter von 63 Jahren möglich",
        "Sie bleiben wirtschaftlicher Eigentümer Ihrer Immobilie",
        "Sie können frei über die Nutzung Ihrer Immobilie entscheiden",
        "eine spätere Vermietung Ihrer Immobilie ist möglich",
        "auch für gewerbliche und vermietete Immobilien möglich",
        "erstrangige Absicherung Ihrer Rechte im Grundbuch",
        "hohe Auszahlungen",
        "lebenslanges Nießbrauchrecht für Sie und Ihren Partner",
      ],
      disadvantages: [
        "provisionspflichtiger Verkauf",
        "Sie tragen weiterhin im Wesentlichen alle Kosten für Reparaturen und Instandhaltungen",
        "es muss ein privater Käufer beziehungsweise Kapitalanleger für Ihre Immobilie gefunden werden",
      ],
      ...calculateNiessbrauch(calculationArguments),
    },
    {
      id: ModelKey.FAMILIE,
      letter: "C",
      title: "Verkauf innerhalb der Familie",
      subTitle: "Für alle Immobilienarten geeignet",
      mainColor: "#3a6a74",
      lightColor: "#9db5ba",
      advantages: [
        "Ihre Immobilie verbleibt auch nach Ihrem Tod im Familienbesitz",
      ],
      disadvantages: [
        "erstrangige Absicherung Ihrer Rechte im Grundbuch bei Finanzierung des Kaufpreises in der Regel nicht möglich",
        "realisierbare Auszahlungen oft niedriger als bei einem Verkauf an Dritte",
      ],
      disabled: false,
      profitInPercent: 0,
    },
    {
      id: ModelKey.RUECKMIETE,
      letter: "D",
      title: "Einmalzahlung & Rückmiete",
      subTitle: "Maximaler Auszahlungsbetrag mit flexibler Laufzeit",
      mainColor: "#2d3e63",
      lightColor: "#8a9ab7",
      advantages: [
        "Sie erhalten den gesamten Wert Ihrer Immobilie als Einmalzahlung und zahlen nur monatsweise für die tatsächlich benötigte Nutzung",
        "Sie können mit einer Frist von 3 Monaten das Vertragsverhältnis kündigen, durch den Käufer sind Sie unkündbar",
      ],
      disadvantages: [
        "erstrangige Absicherung Ihrer Rechte im Grundbuch häufig nicht möglich",
        "bei einer langen Nutzungsabsicht in der Regel nicht wirtschaftlich",
      ],
      ...calculateRueckmiete(calculationArguments),
    },
    {
      id: ModelKey.IMMORENTE,
      letter: "E",
      title: "Verkauf mit Immobilienrente",
      subTitle: "Zeitlich begrenzt und lebenslang möglich",
      mainColor: "#902b27",
      lightColor: "#c9a19d",
      advantages: [
        "anstelle eines hohen Einmalbetrags erhalten Sie regelmäßige monatliche Rentenzahlungen",
        "Ihre Rechte und Rentenzahlungen werden im Grundbuch erstrangig für Sie abgesichert",
      ],
      disadvantages: [
        "eventuell werden Steuern auf die Rentenzahlungen fällig",
        "Beiträge in die gesetzliche Krankenkasse können sich marginal erhöhen",
      ],
      disabled: false,
      profitInPercent: 0,
    },
    {
      id: ModelKey.UMKEHR,
      letter: "F",
      title: "Klassische Umkehrhypothek",
      subTitle: "Für alle Immobilienarten geeignet",
      mainColor: "#e0ba62",
      lightColor: "#f5e8b5",
      advantages: [
        "Sie bleiben weiterhin Eigentümer und Ihre Erben können ggfs. aus einer eventuell positiven Wertentwicklung Ihrer Immobilie finanziellen Nutzen ziehen.",
      ],
      disadvantages: [
        "Nachteilhaft ist, dass die Höhe des für Sie verfügbaren Kapitals deutlich geringer ist als bei den Verrentungsmodellen A, B, D und E.",
      ],
      ...calculateUmkehrhypothek(calculationArguments),
    },
    {
      id: ModelKey.SENIORENKREDIT,
      letter: "G",
      title: "Seniorenkredit",
      subTitle:
        "Keine Tilgung innerhalb der Laufzeit. Auch durch Hausbank möglich.",
      mainColor: "#8b7053",
      lightColor: "#c9b7a9",
      advantages: [
        "Sie bleiben weiterhin Eigentümer der Immobilie und können diese, solange Sie möchten, weiter bewohnen.",
        "Der Seniorenkredit bietet Ihnen die Möglichkeit, Liquidität aus Ihrer Immobilie zu ziehen und erhält Ihnen die Option Ihr Heim, wenn auch verschuldet, an Ihre Erben zu übertragen.",
      ],
      disadvantages: [
        "Nachteilhaft ist, dass die Höhe des verfügbaren Kapitals geringer ist als bei den Verrentungsmodellen A, B, D und E. Ihre monatliche Liquidität wird durch die regelmäßig zu leistenden Zins­zahlungen geschmälert. Außerdem müssen Sie weiterhin alle mit der Immobilie verbundenen Kosten selbst tragen.",
        "Die Kosten für die Grundschuld­bestellung und Vertragsvermittlung müssen von Ihnen im Voraus getragen werden.",
      ],
      ...calculateSeniorenkredit(calculationArguments),
    },
    {
      id: ModelKey.TEILVERKAUF,
      letter: "H",
      title: "Teilverkauf",
      subTitle:
        "Sie bleiben Eigentümer Ihrer Immobilie. Ein Rückkauf ist möglich.",
      mainColor: "#587944",
      lightColor: "#a3b28e",
      advantages: [
        "bei einer hohen zukünftigen Wertsteigerung Ihrer Immobilie eventuell profitabel",
      ],
      disadvantages: [
        "Zahlung eines häufig hohen Nutzungsentgelts für den verkauften Teil Ihrer Immobilie",
        "unattraktive Konditionen",
        "der nicht verkaufte Teil haftet für die Profite des veräußerten Teils",
        "aufgrund befristeter Zinsfest­schreibung besteht die hohe Wahrscheinlichkeit, dass das monatliche Nutzungs­entgelt zukünftig steigt",
        "aufgrund befristeter Zinsfestschreibung besteht die Gefahr, dass die Zinsen in Zukunft steigen könnten",
        "häufig keine Übernahme von Kosten für Reparaturen und Instand­haltungen oder nur mit anschließender Erhöhung des monatlichen Nutzungsentgelts",
      ],
      ...calculateTeilverkauf(calculationArguments),
    },
    {
      id: ModelKey.NEUES_ZUHAUSE,
      letter: "I",
      title: "Neues Zuhause innerhalb von 3 Jahren",
      subTitle:
        "Sie erhalten den Kaufpreis direkt und haben 3 Jahre Zeit für den Umzug ins neue Zuhause.",
      mainColor: "#852a47",
      lightColor: "#c9a1b0",
      advantages: [
        "maximaler Auszahlbetrag",
        "professionelle Unterstützung bei der Wertermittlung, bei der Beschaffung aller notwendigen Unterlagen (Energieausweis, Wohnflächenberechnung, Auskunft aus dem Baulastenverzeichnis, aktueller Grundbuchauszug usw.), Besichtigungen, Überprüfung der Bonität & Seriosität potenziell Käufer, Moderation von Verhandlungen, Begleitung zu Notarterminen",
        "Sie können bis 3 Jahre in Ihrer Immobilie wohnen bleiben und entscheiden selbst mit einer dreimonatigen Kündigungsfrist, wann Sie ausziehen.",
      ],
      disadvantages: [
        "Der Auszugstermin wird im notariellen Kaufvertrag vereinbart und ist verbindlich.",
      ],
      disabled: false,
      profitInPercent: 0,
    },
  ];
  const displayModels = models.map((model) => ({
    ...model,
    sellingprice:
      calculationArguments.marketValue * (model.profitInPercent / 100),
  }));
  return displayModels;
}
