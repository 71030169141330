import { initializeApp } from "firebase/app";
import { browserLocalPersistence, initializeAuth } from "firebase/auth";
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDJ5v1vq_HH5okz3xxrp1Kj1dmWh4Rwba4",
  authDomain: "rivho-206a8.firebaseapp.com",
  projectId: "rivho-206a8",
  storageBucket: "rivho-206a8.appspot.com",
  messagingSenderId: "986182890072",
  appId: "1:986182890072:web:d621c0f8b2fe728ac9902d",
};

const app = initializeApp(firebaseConfig);

const authenticationProvider = initializeAuth(app, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: undefined,
});

const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
});

export { db, authenticationProvider };
