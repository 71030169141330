import {
  type PropStackUnitType,
  type LocalUnit,
  GENDER,
  type PropstackCustomUnitFields,
  type LocalCustomUnitFields,
  MARITAL_STATUS,
} from "./propstackTypes";

function mapSelectOptionsToLocal(
  propstackObject: PropstackCustomUnitFields
): LocalCustomUnitFields {
  const mapSex = (value: number | null | undefined) => {
    switch (value) {
      case 0:
        return GENDER.FEMALE;
      case 1:
        return GENDER.MALE;
      default:
        return null;
    }
  };
  const owner_sex = propstackObject.owner_sex.value;
  const partner_sex = propstackObject.partner_sex.value;

  const mapMaritalSatus = (value: number | null | undefined) => {
    switch (value) {
      case 0:
        return MARITAL_STATUS.Divorced;
      case 1:
        return MARITAL_STATUS.Single;
      case 2:
        return MARITAL_STATUS.Married;
      case 3:
        return MARITAL_STATUS.DomesticPartnership;
      case 4:
        return MARITAL_STATUS.Widowed;
      default:
        return null;
    }
  };
  const marital_status = propstackObject.marital_status.value;

  return {
    ...propstackObject,
    owner_sex: { value: mapSex(owner_sex) },
    partner_sex: { value: mapSex(partner_sex) },
    marital_status: { value: mapMaritalSatus(marital_status) },
  };
}

export function convertToLocalFormat(dbUnit: PropStackUnitType): LocalUnit {
  return {
    ...dbUnit,
    id: `${dbUnit.id}`,
    custom_fields: mapSelectOptionsToLocal(dbUnit.custom_fields),
  };
}

export const unwrapValueField = (objectToUnwrap: Record<string, any>) => {
  const unwrappedObject = Object.keys(objectToUnwrap).reduce((newObj, key) => {
    const item = objectToUnwrap[key];
    // Check if item is an object and has a 'value' attribute
    if (item && typeof item === "object" && "value" in item) {
      newObj[key] = item.value;
    } else {
      // Copy the item as it is if it doesn't meet the condition
      newObj[key] = item;
    }
    return newObj;
  }, {} as { [key: string]: any });
  return unwrappedObject;
};

const mapSelectOptionsToPropstack = (
  localObject: LocalCustomUnitFields
): PropstackCustomUnitFields => {
  const mapSex = (value: GENDER | null) => {
    switch (value) {
      case GENDER.FEMALE:
        return 0;
      case GENDER.MALE:
        return 1;
      default:
        return null;
    }
  };
  const owner_sex = localObject.owner_sex.value;
  const partner_sex = localObject.partner_sex.value;

  const mapMaritalSatus = (value: MARITAL_STATUS | null | undefined) => {
    switch (value) {
      case MARITAL_STATUS.Divorced:
        return 0;
      case MARITAL_STATUS.Single:
        return 1;
      case MARITAL_STATUS.Married:
        return 2;
      case MARITAL_STATUS.DomesticPartnership:
        return 3;
      case MARITAL_STATUS.Widowed:
        return 4;
      default:
        return null;
    }
  };
  const marital_status = localObject.marital_status.value;

  return {
    ...localObject,
    owner_sex: { value: mapSex(owner_sex) },
    partner_sex: { value: mapSex(partner_sex) },
    marital_status: { value: mapMaritalSatus(marital_status) },
  };
};

export function convertToPropstackFormat(localUnit: Partial<LocalUnit>) {
  return {
    ...unwrapValueField(localUnit),
    custom_fields: localUnit.custom_fields
      ? unwrapValueField(mapSelectOptionsToPropstack(localUnit.custom_fields))
      : undefined,
    id: undefined,
    broker: undefined,
  };
}
