import { ref, computed, watch, toRaw } from "vue";
import { defineStore } from "pinia";

import { useRoute } from "vue-router";
import { PropstackAPIConnector } from "@/propstack/propstackConnector";
import type { LocalUnit } from "@/propstack/propstackTypes";
import { patchArray } from "@/common/arrayUtils";

import { compareObjects } from "@/common/objectUtils";

const BE_POLLING_RATE_IN_MS = 20000;
const NUM_MAX_ATTEMPTS = 5;

type ChangedLocalUnit = Partial<LocalUnit> & { id: string };

export const useUnitStore = defineStore(
  "unit-store",
  () => {
    const currentRoute = useRoute();
    const propStackConnector = new PropstackAPIConnector();

    const allUnits = ref<LocalUnit[]>([]);
    const changedUnits = ref<LocalUnit[]>([]);
    const currentUnit = ref<LocalUnit | null>(null);
    const attemptsPerUnit = new Map<string, number>();

    const allUnitsSorted = computed(() => {
      if (!allUnits.value) return [];
      return allUnits.value.sort(
        (a, b) =>
          new Date(b.updated_at ?? b.created_at).getTime() -
          new Date(a.updated_at ?? a.created_at).getTime()
      );
    });

    const fetchUnits = async () => {
      propStackConnector.getObjects().then((result) => {
        allUnits.value = patchArray(allUnits.value, result);
        if (allUnits.value.length > 50) {
          allUnits.value.length = 50; // Limit the number of units to 50
        }
      });
    };

    const findActuallyChangedUnits = (
      changedUnits: ChangedLocalUnit[],
      unit: LocalUnit
    ) => {
      const originalUnit = allUnits.value.find((u) => u.id === unit.id);
      if (!originalUnit) return changedUnits;
      const diff = compareObjects(originalUnit, unit);

      if (Object.keys(diff).length > 0) {
        changedUnits.push({
          ...diff,
          // We need to always send the custom_fields, otherwise they are reset to null
          // in propstack
          custom_fields: unit.custom_fields,
          id: unit.id,
        });
      }
      return changedUnits;
    };

    const patchUpdatedUnits = (updatedUnits: (LocalUnit | null)[]) => {
      // Remove units that were successfully updated from the list
      // or that exceeded the num of consecutive attempts to update
      changedUnits.value = changedUnits.value.filter((unit) => {
        if (updatedUnits.some((u) => u?.id === unit.id)) return false;
        const attempts = attemptsPerUnit.get(unit.id) ?? 0;
        attemptsPerUnit.set(unit.id, attempts + 1);
        if (attempts > NUM_MAX_ATTEMPTS) {
          attemptsPerUnit.delete(unit.id);
          return false;
        }
      });

      // Update current unit if necessary
      const updatedCurrentUnit = updatedUnits.find(
        (unit) => unit?.id === currentUnit.value?.id
      );
      currentUnit.value = updatedCurrentUnit ?? currentUnit.value;
    };

    const updateUnits = async () => {
      if (!changedUnits.value.length) return;
      const actuallyChangedUnits = changedUnits.value.reduce(
        findActuallyChangedUnits,
        [] as ChangedLocalUnit[]
      );
      if (!actuallyChangedUnits.length) return;
      const results = Promise.all(
        actuallyChangedUnits.map((unit) =>
          propStackConnector.updateObject(unit.id, unit)
        )
      );
      return results;
    };

    fetchUnits();

    setInterval(async () => {
      if (!window.navigator.onLine) return;
      const updateResult = await updateUnits();
      if (updateResult) {
        patchUpdatedUnits(updateResult);
      }
      fetchUnits();
    }, BE_POLLING_RATE_IN_MS);

    watch(
      [allUnits, currentRoute],
      () => {
        const unitId = currentRoute.params?.slug;
        if (!unitId || Array.isArray(unitId)) {
          currentUnit.value = null;
          return;
        }

        const changedUnit = changedUnits.value.find((u) => u.id === unitId);
        const unchangedUnit =
          allUnits.value.find((u) => u.id === unitId) ?? null;

        currentUnit.value =
          changedUnit ?? structuredClone(toRaw(unchangedUnit));
      },
      { immediate: true }
    );

    watch(
      currentUnit,
      () => {
        if (!currentUnit.value) return;

        const index = changedUnits.value.findIndex(
          (u) => u.id === currentUnit.value?.id
        );
        // if (index >= 0) {
        //   changedUnits.value[index] = currentUnit.value;
        // } else {
        //   changedUnits.value.push(currentUnit.value);
        // }
        if (index < 0) {
          changedUnits.value.push(currentUnit.value);
        }
      },
      { deep: true }
    );

    return {
      allUnits,
      allUnitsSorted,
      currentUnit,
      changedUnits,
    };
  },
  {
    persist: {
      paths: ["allUnits", "changedUnits"],
    },
  }
);
