<template>
  <v-navigation-drawer
    id="sidebar"
    model-value
    color="hoffmannOrange"
    permanent
    rail
  >
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
      "
      class="p-2"
    >
      <v-avatar
        image="/HOF_Favicon.svg"
        class="mx-auto mt-2"
        :to="{ name: ROUTE_NAME.ALL_UNITS_VIEW }"
      ></v-avatar>
      <v-list nav>
        <v-tooltip
          v-for="route in routes"
          :key="route.title"
          :text="route.tooltipText"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              :prepend-icon="route.icon"
              :title="route.title"
              :to="route.to"
              v-bind="props"
              color="hoffmannRot"
            ></v-list-item>
          </template>
        </v-tooltip>
      </v-list>
      <div v-if="!isObjectRoute">
        <v-tooltip text="Ausloggen">
          <template v-slot:activator="{ props: logout_props }">
            <v-btn
              v-bind="logout_props"
              icon="mdi-logout"
              :to="{ name: 'login' }"
              class="bg-hoffmannRot mx-auto mb-2"
              @click="onClickLogout"
            />
          </template>
        </v-tooltip>
      </div>
      <v-tooltip text="Zurück zur Kundeliste" v-else>
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-home-city-outline"
            class="bg-hoffmannRot mx-auto mb-2"
            @click="onLeaveUnit"
          />
        </template>
      </v-tooltip>
    </div>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { computed, ref, watch } from "vue";
import { useAuthenticationService } from "@/router/authenticationService";
import { ROUTE_NAME } from "@/router/routeNames";

const route = useRoute();
const router = useRouter();

const isObjectRoute = ref(false);

watch(
  route,
  () => {
    if (route.fullPath.includes(`${ROUTE_NAME.UNIT_VIEW}/`)) {
      isObjectRoute.value = true;
    } else {
      isObjectRoute.value = false;
    }
  },
  { immediate: true }
);

const routes = computed(() => {
  if (isObjectRoute.value) {
    return [
      {
        title: "Objektdaten",
        icon: "mdi-calculator",
        to: { name: ROUTE_NAME.UNIT_INPUT_VIEW },
        tooltipText: "Objektdaten eingeben",
      },
      {
        title: "Ergebnis",
        icon: "mdi-equal-box",
        to: { name: ROUTE_NAME.UNIT_RESULT_VIEW },
        tooltipText: "Verrentungsergebnis",
      },
    ];
  }
  return [
    {
      title: "Objektliste",
      icon: "mdi-format-list-bulleted-square",
      to: { name: ROUTE_NAME.ALL_UNITS_VIEW },
      tooltipText: "Alle Objekte anzeigen",
    },
    {
      title: "Einstellungen",
      icon: "mdi-cogs",
      to: { name: ROUTE_NAME.GLOBAL_SETTINGS },
      tooltipText: "Globale Einstellungen vornehmen",
    },
  ];
});

const onLeaveUnit = () => {
  isObjectRoute.value = false;
  router.push({ name: ROUTE_NAME.ALL_UNITS_VIEW });
};

const onClickLogout = () => {
  useAuthenticationService().logout();
};
</script>
