<template>
  <div v-if="currentUnit">
    <div style="display: flex; gap: 2rem">
      <v-select
        v-if="currentUnit.custom_fields.owner_sex"
        v-model="currentUnit.custom_fields.owner_sex.value"
        clearable
        label="Anrede"
        :items="salutationTypeSelection"
        variant="underlined"
        color="hoffmannRot"
        density="compact"
        style="max-width: 600px; flex: 1"
      />
      <v-text-field
        v-if="currentUnit.custom_fields.owner_name"
        v-model.trim="currentUnit.custom_fields.owner_name.value"
        color="hoffmannRot"
        label="Name (inkl. Titeln)"
        variant="underlined"
        density="compact"
        style="flex: 1; max-width: 600px"
      />
    </div>
    <v-dialog width="500" v-if="currentUnit.custom_fields.owner_birthday">
      <template v-slot:activator="{ props }">
        <v-text-field
          v-bind="props"
          :model-value="
            parseToDisplayableString(
              currentUnit.custom_fields.owner_birthday.value
            )
          "
          color="hoffmannRot"
          label="Geburtsdatum"
          variant="underlined"
          density="compact"
          style="max-width: 600px"
          readonly
        />
      </template>
      <template v-slot:default="{ isActive }">
        <v-card title="Geburtsdatum auswählen">
          <v-date-picker
            v-model="currentUnit.custom_fields.owner_birthday.value"
            color="hoffmannRot"
            hide-header
            style="width: 100%"
            year="1950"
            @update:model-value="isActive.value = false"
          />
          <v-card-actions>
            <v-spacer />
            <v-btn text="Abbrechen" @click="isActive.value = false" />
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <div width="100% " style="border-bottom: 1px solid gray">
      <v-label class="my-4 text-h5" text-color="gray" text="Verhältnisse" />
    </div>
    <div width="100%" class="mt-4 px-3">
      <v-select
        v-model="currentUnit.custom_fields.marital_status.value"
        clearable
        label="Familienstand"
        :items="maritalStatusTypeSelection"
        variant="underlined"
        color="hoffmannRot"
        style="max-width: 600px"
      />
      <div
        v-if="
          currentUnit.custom_fields.marital_status?.value &&
          [MARITAL_STATUS.DomesticPartnership, MARITAL_STATUS.Married].includes(
            currentUnit.custom_fields.marital_status.value
          )
        "
      >
        <div style="display: flex; gap: 2rem">
          <v-select
            v-model.trim="currentUnit.custom_fields.partner_sex.value"
            clearable
            label="Anrede Partner"
            :items="salutationTypeSelection"
            variant="underlined"
            color="hoffmannRot"
            density="compact"
            style="max-width: 600px"
          />
          <v-text-field
            v-model.trim="currentUnit.custom_fields.partner_name.value"
            color="hoffmannRot"
            label="Anrede (inkl. Titeln)"
            variant="underlined"
            density="compact"
            style="flex: 1; max-width: 600px"
          />
        </div>
        <v-dialog width="500" v-if="currentUnit.custom_fields.partner_birthday">
          <template v-slot:activator="{ props }">
            <v-text-field
              v-bind="props"
              :model-value="
                parseToDisplayableString(
                  currentUnit.custom_fields.partner_birthday.value
                )
              "
              color="hoffmannRot"
              label="Geburtsdatum"
              variant="underlined"
              density="compact"
              style="max-width: 600px"
              readonly
            />
          </template>
          <template v-slot:default="{ isActive }">
            <v-card title="Geburtsdatum auswählen">
              <v-date-picker
                v-model="currentUnit.custom_fields.partner_birthday.value"
                color="hoffmannRot"
                hide-header
                style="width: 100%"
                year="1950"
                @update:model-value="isActive.value = false"
              />
              <v-card-actions>
                <v-spacer />
                <v-btn text="Abbrechen" @click="isActive.value = false" />
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
      <v-checkbox
        v-model="currentUnit.custom_fields.heritage.value"
        label="Soll die Immobilie vererbt werden?"
        color="hoffmannRot"
        style="flex: 1; max-width: 600px"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRef } from "vue";
import { useUnitStore } from "@/stateManagement/unitStore";
import { MARITAL_STATUS, GENDER } from "@/propstack/propstackTypes";

const unitStore = useUnitStore();
const currentUnit = toRef(unitStore, "currentUnit");

const salutationTypeSelection: string[] = [];
Object.values(GENDER).forEach((value) => {
  salutationTypeSelection.push(value);
});

const maritalStatusTypeSelection: string[] = [];
Object.values(MARITAL_STATUS).forEach((value) => {
  maritalStatusTypeSelection.push(value);
});

const parseToDisplayableString = (date: Date | null | string) => {
  if (date) {
    date = new Date(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }
  return null;
};
</script>
@/propstack/propstackTypes
