<template>
  <template v-if="currentUnit">
    <section class="tw-flex tw-space-x-4">
      <v-text-field
        v-model="currentUnit.street"
        color="hoffmannRot"
        class="tw-flex-1"
        label="Straße"
        variant="underlined"
      />
      <v-text-field
        v-model="currentUnit.house_number"
        color="hoffmannRot"
        class="tw-max-w-32"
        label="Hausnummer"
        variant="underlined"
        type="number"
      />
    </section>
    <section class="tw-flex tw-space-x-4">
      <v-text-field
        v-model="currentUnit.zip_code"
        color="hoffmannRot"
        class="tw-max-w-32"
        label="Postleitzahl"
        variant="underlined"
        type="number"
      />
      <v-text-field
        v-model="currentUnit.city"
        color="hoffmannRot"
        class="tw-flex-1"
        label="Stadt"
        variant="underlined"
      />
    </section>
    <v-radio-group v-model="currentUnit.rs_type" column>
      <div width="100% " style="border-bottom: 1px solid gray">
        <v-label class="mt-2 text-h5" text-color="gray" text="Objekttyp" />
      </div>
      <div width="100%" class="tw-mt-4 tw-flex">
        <v-radio
          label="Wohnung"
          :value="PROPERTY_TYPE.APARTMENT"
          color="hoffmannRot"
          style="flex: 1; max-width: 300px"
        />
        <v-radio
          label="Haus"
          :value="PROPERTY_TYPE.HOUSE"
          color="hoffmannRot"
          style="flex: 1; max-width: 300px"
        />
        <v-radio
          label="Grundstück"
          :value="PROPERTY_TYPE.TRADE_SITE"
          color="hoffmannRot"
          style="flex: 1; max-width: 300px"
        />
      </div>
      <section class="tw-mt-3 tw-ml-3">
        <template v-if="currentUnit.rs_type === PROPERTY_TYPE.APARTMENT">
          <v-select
            v-model="currentUnit.rs_category"
            label="Wohnungsart"
            :items="flatTypeList"
            variant="underlined"
            color="hoffmannRot"
            class="tw-flex-1"
            clearable
          >
            <template #selection="{ item }">
              {{ item.title }}
            </template>
          </v-select>
          <v-text-field
            v-model="currentUnit.floor"
            color="hoffmannRot"
            class="tw-flex-1"
            label="Etage"
            variant="underlined"
            type="number"
          />
        </template>
        <template v-if="currentUnit.rs_type === PROPERTY_TYPE.HOUSE">
          <v-select
            v-model="currentUnit.rs_category"
            label="Hausart"
            :items="houseTypeList"
            variant="underlined"
            color="hoffmannRot"
            class="tw-flex-1"
            clearable
          >
            <template #selection="{ item }">
              {{ item.title }}
            </template>
          </v-select>
          <v-text-field
            v-model="currentUnit.number_of_floors"
            color="hoffmannRot"
            class="tw-flex-1"
            label="Anzahl an Etagen"
            variant="underlined"
            type="number"
          />
        </template>
      </section>
    </v-radio-group>
    <div width="100% " style="border-bottom: 1px solid gray">
      <v-label class="text-h5" text="Eckdaten" />
    </div>
    <div width="100%" class="tw-mt-4 tw-px-3">
      <div style="display: flex; gap: 2rem">
        <v-text-field
          v-model.trim="currentUnit.custom_fields.market_value.value"
          color="hoffmannRot"
          label="Verkehrswert"
          variant="underlined"
          density="compact"
          required
          style="flex: 1; max-width: 600px"
        />
      </div>
      <div style="display: flex; gap: 2rem">
        <v-text-field
          v-model.trim="currentUnit.living_space.value"
          color="hoffmannRot"
          label="Wohnfläche"
          variant="underlined"
          density="compact"
          type="number"
          suffix="m²"
          style="flex: 1; max-width: 400px"
        />

        <v-text-field
          v-model.trim="currentUnit.plot_area.value"
          color="hoffmannRot"
          label="Grundstücksfläche"
          variant="underlined"
          density="compact"
          type="number"
          suffix="m²"
          style="flex: 1; max-width: 400px"
        />
      </div>
      <div style="display: flex; gap: 2rem">
        <v-text-field
          v-model.trim="currentUnit.number_of_rooms.value"
          color="hoffmannRot"
          label="Anzahl Zimmer"
          variant="underlined"
          density="compact"
          type="number"
          style="flex: 1; max-width: 400px"
        />

        <v-text-field
          v-model.trim="currentUnit.number_of_bath_rooms.value"
          color="hoffmannRot"
          label="Anzahl Bäder"
          variant="underlined"
          density="compact"
          type="number"
          style="flex: 1; max-width: 400px"
        />
      </div>
    </div>
    <div width="100% " style="border-bottom: 1px solid gray">
      <v-label class="mt-2 text-h5" text-color="gray" text="Wohndauer" />
    </div>
    <div width="100%" class="mt-4 px-3">
      <div style="display: flex; gap: 2rem">
        <v-checkbox
          v-model="currentUnit.custom_fields.longlife_residence.value"
          label=" Wünschen Sie ein lebenslanges Wohnrecht?"
          color="hoffmannRot"
          class="pl-14 mt-3"
          style="flex: 1; max-width: 500px"
        />

        <v-slider
          v-model="tempSliderModelValue"
          :step="1"
          :max="maxRemainingYears"
          :min="minRemainingYears"
          label="Ungefähre Verbleibedauer:"
          hide-details
          :disabled="currentUnit.custom_fields.longlife_residence.value"
          color="hoffmannOrange"
          track-color="hoffmannRot"
          thumb-color="hoffmannRot"
          style="flex: 2; max-width: 700px"
        >
          <template v-slot:append>
            <v-text-field
              v-model="currentUnit.custom_fields.desired_remaining_stay.value"
              type="number"
              style="width: 80px"
              density="compact"
              hide-details
              variant="underlined"
              :max="maxRemainingYears"
              :min="minRemainingYears"
              class="mb-5"
          /></template>
        </v-slider>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { toRef, computed } from "vue";
import { useUnitStore } from "@/stateManagement/unitStore";
import {
  FLAT_TYPE,
  HOUSE_TYPE,
  PROPERTY_TYPE,
} from "@/propstack/propstackTypes";

const unitStore = useUnitStore();
const currentUnit = toRef(unitStore, "currentUnit");

const flatTypeList: { title: string; value: FLAT_TYPE }[] = [
  { title: "Dachgeschoss", value: FLAT_TYPE.ROOF_STOREY },
  { title: "Loft", value: FLAT_TYPE.LOFT },
  { title: "Maisonette", value: FLAT_TYPE.MAISONETTE },
  { title: "Penthouse", value: FLAT_TYPE.PENTHOUSE },
  { title: "Terrassenwohnung", value: FLAT_TYPE.TERRACED_FLAT },
  { title: "Erdgeschosswohnung", value: FLAT_TYPE.GROUND_FLOOR },
  { title: "Etagenwohnung", value: FLAT_TYPE.APARTMENT },
  { title: "Hochparterre", value: FLAT_TYPE.RAISED_GROUND_FLOOR },
  { title: "Souterrain", value: FLAT_TYPE.HALF_BASEMENT },
  { title: "Sonstige", value: FLAT_TYPE.OTHER },
];

const houseTypeList: { title: string; value: HOUSE_TYPE }[] = [
  { title: "Einfamilienhaus", value: HOUSE_TYPE.SINGLE_FAMILY_HOUSE },
  { title: "Zweifamilienhaus", value: HOUSE_TYPE.TWO_FAMILY_HOUSE },
  { title: "Reihenmittelhaus", value: HOUSE_TYPE.MID_TERRACE_HOUSE },
  { title: "Reiheneckhaus", value: HOUSE_TYPE.TERRACE_END_HOUSE },
  { title: "Mehrfamilienhaus", value: HOUSE_TYPE.MULTI_FAMILY_HOUSE },
  { title: "Doppelhaushälfte", value: HOUSE_TYPE.SEMIDETACHED_HOUSE },
];

/** Needed to meet vuetify typing requirements */
const tempSliderModelValue = computed({
  get() {
    return (
      currentUnit.value?.custom_fields.desired_remaining_stay.value ?? undefined
    );
  },
  set(value) {
    if (!currentUnit.value?.custom_fields) return;
    currentUnit.value.custom_fields.desired_remaining_stay.value =
      value ?? null;
  },
});

// const apartmentTypeSelection: string[] = [];
// Object.values(ApartmentTypesEnum).forEach((value) => {
//   apartmentTypeSelection.push(value);
// });

// const houseTypeSelection: string[] = [];
// Object.values(HouseTypesEnum).forEach((value) => {
//   houseTypeSelection.push(value);
// });

// const energyCertificateSelection: string[] = [];
// Object.values(EnergyCertificateTypesEnum).forEach((value) => {
//   energyCertificateSelection.push(value);
// });

const minRemainingYears = 1;
const maxRemainingYears = 30;
</script>
@/propstack/propstackTypes
