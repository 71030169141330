export const BERLIN_ZIP_CODES: string[] = [
  "10115",
  "10117",
  "10119",
  "10178",
  "10179",
  "10243",
  "10245",
  "10247",
  "10249",
  "10315",
  "10317",
  "10318",
  "10319",
  "10365",
  "10367",
  "10369",
  "10405",
  "10407",
  "10409",
  "10435",
  "10437",
  "10439",
  "10551",
  "10553",
  "10555",
  "10557",
  "10559",
  "10585",
  "10587",
  "10589",
  "10623",
  "10625",
  "10627",
  "10629",
  "10707",
  "10709",
  "10711",
  "10713",
  "10715",
  "10717",
  "10719",
  "10777",
  "10779",
  "10781",
  "10783",
  "10785",
  "10787",
  "10789",
  "10823",
  "10825",
  "10827",
  "10829",
  "10961",
  "10963",
  "10965",
  "10967",
  "10969",
  "10997",
  "10999",
  "12043",
  "12045",
  "12047",
  "12049",
  "12051",
  "12053",
  "12055",
  "12057",
  "12059",
  "12099",
  "12101",
  "12103",
  "12105",
  "12107",
  "12109",
  "12157",
  "12159",
  "12161",
  "12163",
  "12165",
  "12167",
  "12169",
  "12203",
  "12205",
  "12207",
  "12209",
  "12247",
  "12249",
  "12277",
  "12279",
  "12305",
  "12307",
  "12309",
  "12347",
  "12349",
  "12351",
  "12353",
  "12355",
  "12357",
  "12359",
  "12435",
  "12437",
  "12439",
  "12459",
  "12487",
  "12489",
  "12524",
  "12526",
  "12527",
  "12555",
  "12557",
  "12559",
  "12587",
  "12589",
  "12619",
  "12621",
  "12623",
  "12627",
  "12629",
  "12679",
  "12681",
  "12683",
  "12685",
  "12687",
  "12689",
  "13051",
  "13053",
  "13055",
  "13057",
  "13059",
  "13086",
  "13088",
  "13089",
  "13125",
  "13127",
  "13129",
  "13156",
  "13158",
  "13159",
  "13187",
  "13189",
  "13347",
  "13349",
  "13351",
  "13353",
  "13355",
  "13357",
  "13359",
  "13403",
  "13405",
  "13407",
  "13409",
  "13435",
  "13437",
  "13439",
  "13465",
  "13467",
  "13469",
  "13503",
  "13505",
  "13507",
  "13509",
  "13581",
  "13583",
  "13585",
  "13587",
  "13589",
  "13591",
  "13593",
  "13595",
  "13597",
  "13599",
  "13627",
  "13629",
  "14050",
  "14052",
  "14053",
  "14055",
  "14057",
  "14059",
  "14089",
  "14109",
  "14129",
  "14163",
  "14165",
  "14167",
  "14169",
  "14193",
  "14195",
  "14197",
  "14199",
];

export const LIFE_EXPECTANCY_TABLE = [
  { age: 60, m: 21.46, w: 25.18 },
  { age: 61, m: 20.67, w: 24.3 },
  { age: 62, m: 19.89, w: 23.44 },
  { age: 63, m: 19.12, w: 22.58 },
  { age: 64, m: 18.37, w: 21.74 },
  { age: 65, m: 17.63, w: 20.9 },
  { age: 66, m: 16.91, w: 20.07 },
  { age: 67, m: 16.2, w: 19.25 },
  { age: 68, m: 15.51, w: 18.43 },
  { age: 69, m: 14.82, w: 17.63 },
  { age: 70, m: 14.15, w: 16.84 },
  { age: 71, m: 13.48, w: 16.05 },
  { age: 72, m: 12.83, w: 15.28 },
  { age: 73, m: 12.18, w: 14.51 },
  { age: 74, m: 11.55, w: 13.76 },
  { age: 75, m: 10.92, w: 13.01 },
  { age: 76, m: 10.32, w: 12.28 },
  { age: 77, m: 9.71, w: 11.56 },
  { age: 78, m: 9.12, w: 10.86 },
  { age: 79, m: 8.54, w: 10.17 },
  { age: 80, m: 7.97, w: 9.49 },
  { age: 81, m: 7.43, w: 8.83 },
  { age: 82, m: 6.9, w: 8.19 },
  { age: 83, m: 6.39, w: 7.58 },
  { age: 84, m: 5.9, w: 6.99 },
  { age: 85, m: 5.43, w: 6.43 },
  { age: 86, m: 5.0, w: 5.9 },
  { age: 87, m: 4.59, w: 5.42 },
  { age: 88, m: 4.22, w: 4.97 },
  { age: 89, m: 3.87, w: 4.54 },
  { age: 90, m: 3.56, w: 4.16 },
  { age: 91, m: 3.27, w: 3.81 },
  { age: 92, m: 3.0, w: 3.49 },
  { age: 93, m: 2.77, w: 3.21 },
  { age: 94, m: 2.56, w: 2.95 },
  { age: 95, m: 2.38, w: 2.72 },
  { age: 96, m: 2.22, w: 2.52 },
  { age: 97, m: 2.11, w: 2.35 },
  { age: 98, m: 1.99, w: 2.2 },
  { age: 99, m: 1.87, w: 2.08 },
  { age: 100, m: 1.75, w: 1.97 },
];
