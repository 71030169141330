<template>
  <section class="tw-space-y-3" v-if="currentUnit">
    <section class="tw-flex tw-space-x-8">
      <v-text-field
        v-model="currentUnit.custom_fields.niessbrauch_roi.value"
        color="hoffmannRot"
        label="Nießbrauch Rendite"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-percent"
      />
      <v-text-field
        v-model="currentUnit.custom_fields.niessbrauch_value_increase.value"
        color="hoffmannRot"
        label="Nießbrauch Wertsteigerung"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-percent"
      />
    </section>
    <section class="tw-flex tw-space-x-8">
      <v-text-field
        v-model.trim="currentUnit.custom_fields.rueckmiete_roi.value"
        color="hoffmannRot"
        label="Rückmiete Rendite"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-percent"
      />
      <v-text-field
        v-model.trim="currentUnit.custom_fields.rueckmiete_depreciation.value"
        color="hoffmannRot"
        label="Rückmiete Wertverlust"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-percent"
      />
    </section>
    <section class="tw-flex tw-space-x-8">
      <v-text-field
        v-model.trim="currentUnit.custom_fields.umkehr_interest_rate.value"
        color="hoffmannRot"
        label="Umkehrhypothek Zinsen"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-percent"
      />
      <v-text-field
        v-model.trim="currentUnit.custom_fields.umkehr_onetime_payment.value"
        color="hoffmannRot"
        label="Umkehrhypothek Einmalzahlung"
        variant="underlined"
        type="number"
        prepend-inner-icon="mdi-currency-eur"
      />
    </section>
    <v-text-field
      v-model.trim="
        currentUnit.custom_fields.seniorenkredit_interest_rate.value
      "
      color="hoffmannRot"
      label="Seniorenkredit Zinsen"
      variant="underlined"
      type="number"
      prepend-inner-icon="mdi-percent"
      class="tw-max-w-[49%]"
    />
    <v-text-field
      v-model.trim="currentUnit.custom_fields.teilverkauf_interest_rate.value"
      color="hoffmannRot"
      label="Teilverkauf Zinsen"
      variant="underlined"
      type="number"
      prepend-inner-icon="mdi-percent"
      class="tw-max-w-[49%]"
    />
  </section>
</template>

<script setup lang="ts">
import { useUnitStore } from "@/stateManagement/unitStore";
import { toRef } from "vue";

const currentUnit = toRef(useUnitStore(), "currentUnit");
</script>
