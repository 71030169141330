<template>
  <div
    style="
      max-height: 100vh;
      background-color: rgb(var(--v-theme-hoffmannOrange));
    "
  >
    <section class="tw-flex tw-items-center tw-ml-3 tw-pt-2">
      <v-img
        v-if="currentUnit?.images.length"
        class="tw-rounded-full !tw-max-w-[40px]"
        cover
        :src="currentUnit.images[0].small_thumb_url"
      />
      <v-avatar v-else size="40" image="/default_unit_image.png" />
      <h4 class="tw-text-white text-h4 tw-ml-3">{{ currentUnit?.name }}</h4>
    </section>
    <v-tabs v-model="tab" class="tw-text-white tw-mt-3" hide-slider>
      <v-tab value="masterData">Personendaten</v-tab>
      <v-tab value="realEstateObjectData">Objektdaten</v-tab>
      <v-tab value="modelSettingsData">Verrentungsdaten</v-tab>
    </v-tabs>
    <v-window v-model="tab" class="bg-hoffmannRosa tw-p-8">
      <v-window-item value="masterData">
        <PersonalDataForm />
      </v-window-item>
      <v-window-item value="realEstateObjectData">
        <RealEstateObjectDataForm />
      </v-window-item>
      <v-window-item value="modelSettingsData">
        <ModelSettingsDataForm />
      </v-window-item>
    </v-window>
  </div>
</template>

<script setup lang="ts">
import { getGlobalSettingsService } from "@/features/globalSettings/settingService";
import PersonalDataForm from "@/features/unitDetails/PersonalDataForm.vue";
import RealEstateObjectDataForm from "@/features/unitDetails/RealEstateObjectDataForm.vue";
import type { LocalCustomUnitFields } from "@/propstack/propstackTypes";
import { useUnitStore } from "@/stateManagement/unitStore";
import { watch, ref, toRef, unref } from "vue";
import ModelSettingsDataForm from "@/features/unitDetails/ModelSettingsDataForm.vue";

const tab = ref(null);

const currentUnit = toRef(useUnitStore(), "currentUnit");
const globalSettingService = getGlobalSettingsService();

watch(
  currentUnit,
  () => {
    if (!currentUnit.value) return;
    if (currentUnit.value.custom_fields.is_initialized.value) return;

    // If an object has never been seen before, we need to initialize some of the fields
    const globalSettings = unref(
      globalSettingService.getSyncedGlobalSettings()
    );
    const settingFields: Partial<LocalCustomUnitFields> = {
      is_initialized: { value: true },
      longlife_residence: { value: true },
      niessbrauch_roi: { value: globalSettings.nießbrauchROI },
      niessbrauch_value_increase: {
        value: globalSettings.nießbrauchValueIncrease,
      },
      rueckmiete_roi: { value: globalSettings.rueckmieteROI },
      rueckmiete_depreciation: { value: globalSettings.rueckmieteDepreciation },
      seniorenkredit_interest_rate: {
        value: globalSettings.seniorenkreditInterestRate,
      },
      teilverkauf_interest_rate: {
        value: globalSettings.teilverkaufInterestRate,
      },
      umkehr_interest_rate: { value: globalSettings.umkehrInterestRate },
      umkehr_onetime_payment: { value: globalSettings.umkehrOnetimePayment },
    };
    currentUnit.value.custom_fields = {
      ...currentUnit.value.custom_fields,
      ...settingFields,
    };
  },
  { immediate: true }
);
</script>

<style scoped>
:deep(.v-tabs .v-tab) {
  @apply tw-ml-2 !tw-rounded-t-md;
  @apply tw-border-t-2 tw-border-x-2;
}
:deep(.v-tabs .v-tab--selected) {
  background-color: rgb(var(--v-theme-hoffmannRosa)) !important;
  @apply tw-text-black;
  @apply tw-border-none;
}
</style>
@/propstack/propstackTypes
