import {
  MARITAL_STATUS,
  type LocalUnit,
  GENDER,
} from "@/propstack/propstackTypes";
import {
  calculateAge,
  getAdditionalPurchasingCostBasedOnZipcode,
  getRelevantPersonWithLifeExpectancy,
  getRemainingTimeForUserPreference,
} from "./calculationUtils";

export type UnitDataError = {
  message: string;
};

const UNIT_DATA_ERROR_MESSAGES = {
  OWNER:
    "Fehlende Eingaben für Haupteigentümer. Bitte Anrede und Geburtsdatum eingeben oder überprüfen",
  PARTNER:
    "Fehlende Eingaben für Partner. Bitte Anrede und Geburtsdatum eingeben oder überprüfen",
  DESIRED_REMAINING_STAY:
    "Fehlende Eingaben für gewünschte Verbleibdauer. Bitte gewünschte Verbleibdauer eingeben oder überprüfen",
  ZIP_CODE: "Ungültige oder fehlende Postleitzahl",
  MARKET_VALUE:
    "Automatische Preisermittlung fehlgeschlagen. Bitte manuell eingeben",
  UNIT: "Ungültige oder fehlende Einheit",
  OTHER: "Unbekannter Fehler. Administrator kontaktieren.",
  MODEL_PARAMS: "Fehlerhafte Modellparameter. Bitte überprüfen",
};

export type ModelSpecificParams = {
  niessbrauchRoi: number;
  niessbrauchValueIncrease: number;
  rueckmieteRoi: number;
  rueckmieteDepreciation: number;
  seniorenkreditInterestRate: number;
  teilverkaufInterestRate: number;
  umkehrInterestRate: number;
  umkehrOnetimePayment: number;
};

export type ValidatedUnitData = {
  persons: { birthday: Date; gender: GENDER }[];
  desiredRemainingStay: number | null;
  marketValue: number;
  zipCode: number;
  modelParameters: ModelSpecificParams;
  isInheritanceDesired: boolean;
};

export function validateUnitData(
  unit: LocalUnit | null
): ValidatedUnitData | string {
  if (!unit) return UNIT_DATA_ERROR_MESSAGES.UNIT;

  const owner = {
    birthday: unit.custom_fields.owner_birthday.value,
    gender: unit.custom_fields.owner_sex.value,
  };
  const coOwner =
    unit.custom_fields.marital_status.value === MARITAL_STATUS.Married
      ? {
          birthday: unit.custom_fields.partner_birthday.value,
          gender: unit.custom_fields.partner_sex.value,
        }
      : undefined;
  const marketValue = unit.custom_fields.market_value.value;
  const zipCode = unit.zip_code;
  const desiredRemainingStay = unit.custom_fields.desired_remaining_stay.value;
  const modelParameters = {
    niessbrauchRoi: unit.custom_fields.niessbrauch_roi.value,
    niessbrauchValueIncrease:
      unit.custom_fields.niessbrauch_value_increase.value,
    rueckmieteRoi: unit.custom_fields.rueckmiete_roi.value,
    rueckmieteDepreciation: unit.custom_fields.rueckmiete_depreciation.value,
    seniorenkreditInterestRate:
      unit.custom_fields.seniorenkredit_interest_rate.value,
    teilverkaufInterestRate: unit.custom_fields.teilverkauf_interest_rate.value,
    umkehrInterestRate: unit.custom_fields.umkehr_interest_rate.value,
    umkehrOnetimePayment: unit.custom_fields.umkehr_onetime_payment.value,
  };

  if (!owner.birthday || !owner.gender) {
    return UNIT_DATA_ERROR_MESSAGES.OWNER;
  }
  if (coOwner && (!coOwner.birthday || !coOwner)) {
    return UNIT_DATA_ERROR_MESSAGES.PARTNER;
  }
  if (!marketValue) {
    return UNIT_DATA_ERROR_MESSAGES.MARKET_VALUE;
  }
  if (!zipCode) {
    return UNIT_DATA_ERROR_MESSAGES.ZIP_CODE;
  }
  if (
    unit.custom_fields.longlife_residence.value !== true &&
    !desiredRemainingStay
  ) {
    return UNIT_DATA_ERROR_MESSAGES.DESIRED_REMAINING_STAY;
  }
  if (
    !modelParameters.niessbrauchRoi ||
    !modelParameters.niessbrauchValueIncrease ||
    !modelParameters.rueckmieteRoi ||
    !modelParameters.rueckmieteDepreciation ||
    !modelParameters.seniorenkreditInterestRate ||
    !modelParameters.teilverkaufInterestRate ||
    !modelParameters.umkehrInterestRate ||
    !modelParameters.umkehrOnetimePayment
  ) {
    return UNIT_DATA_ERROR_MESSAGES.MODEL_PARAMS;
  }

  const persons = [
    { birthday: new Date(owner.birthday), gender: owner.gender },
    ...(coOwner
      ? [{ birthday: new Date(coOwner.birthday!), gender: coOwner.gender! }]
      : []),
  ];
  return {
    persons,
    desiredRemainingStay,
    marketValue,
    zipCode,
    modelParameters: modelParameters as ModelSpecificParams,
    isInheritanceDesired: unit.custom_fields.heritage.value ?? false,
  };
}

export type ModelCalculationArguments = {
  relevantPerson: {
    age: number;
    gender: GENDER;
    lifeExpectancy: number;
  } | null;
  minPersonAge: number;
  occupationPeriod: number;
  isOccupationBasedOnLifeExpectancy: boolean;
  marketValue: number;
  isInheritanceDesired: boolean;
  additionalCostInPercent: number;
  modelParameters: ModelSpecificParams;
};

export function getModelArguments(
  unitData: ValidatedUnitData
): ModelCalculationArguments {
  const minPersonAge = Math.min(
    ...unitData.persons.map((p) => calculateAge(p.birthday))
  );
  const relevantPerson = getRelevantPersonWithLifeExpectancy(unitData.persons);
  const occupationPeriod = getRemainingTimeForUserPreference(
    relevantPerson.lifeExpectancy,
    unitData.desiredRemainingStay
  );
  const isOccupationBasedOnLifeExpectancy =
    occupationPeriod === relevantPerson.lifeExpectancy;
  const additionalCostInPercent = getAdditionalPurchasingCostBasedOnZipcode(
    unitData.zipCode.toString()
  );

  return {
    minPersonAge,
    occupationPeriod,
    additionalCostInPercent,
    relevantPerson,
    isOccupationBasedOnLifeExpectancy,
    ...unitData,
  };
}
